[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: block;
}
#footer {
  display: none;
}
#contact-us-box {
  display: none;
}
#text-area {
  text-align: center;
}
#unsupportedBrowserDIV {
  display: none;
}
#main-area-login .panel-realex-login {
  display: none;
}
.portfolio-style-location {
  display: none;
}
